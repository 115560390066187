import React, { Component } from "react";

class KitLogo extends Component {
	render() {
		if (!this.props.kit || !this.props.kit.logo_url) {
			console.warn("no logo", this.props);
			return null;
		}
		return (
			<div className="KitLogo">
				<img src={this.props.kit.logo_url} alt="logo" />
			</div>
		);
	}
}

export default KitLogo;
