import React, { Component } from "react";
import logo from "./assets/tac-logo-notxt.svg";

import "./styles/App.css";

import colorConvert from "color-convert";

import KitLogo from "./KitLogo";

import Ranking from "./Ranking";
import DataViz from "./DataViz";
import Chat from "./Chat";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      status: { kits: {}, chat: [], timestamp: "", ranking: [], colors: {} },
      myId: 1001
    };
  }

  loadStatus = () => {
    fetch("https://app.teleagriculture.org/api/social/status")
      .then(res => res.json())
      .then(result => {
        if (result.timestamp) {
          if (
            !this.state.status.timestamp ||
            this.state.status.timestamp !== result.timestamp
          ) {
            this.setState({ loaded: true, status: result });
            setTimeout(() => this.sendColors(result.colors), 1000);
            console.log("updating status", this.state.status);
          }
        }
      });
  };

  sendColors = colors => {
    const myColor = colors[this.state.myId];
    console.log("sending colors", myColor, colors);
    if (myColor) {
      const rgbColor = colorConvert.hsl.rgb(myColor.h, myColor.s, myColor.l);
      fetch("http://localhost:5555/color", {
        method: "PUT",
        // mode: "no-cors",
        body: JSON.stringify({
          red: rgbColor[0],
          green: rgbColor[1],
          blue: rgbColor[2]
        })
      }).then(resp => {
        console.log(resp);
      });
    }
    let f = document.getElementById("DataViz-Frame");
    if (!f) {
      console.warn("No #DataViz-Frame");
      return;
    }
    f.contentWindow.postMessage({ colors: colors }, "*");
  };

  componentDidMount() {
    this.loadStatus();
    fetch("http://localhost:5555/whoami")
      .then(res => res.json())
      .then(result => {
        console.log("Got myId", result, result["kit-id"]);
        this.setState({ myId: result["kit-id"] });
      });
    this.interval = setInterval(() => this.loadStatus(), 10000); // every 10 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>TeleAgriCulture_ Rhizomatic Bias</h1>
          <KitLogo kit={this.state.status.kits[this.state.myId]} />
          <span className="Timestamp">{this.state.status.timestamp}</span>
        </header>
        <div className="App-Container">
          <Ranking status={this.state.status} myId={this.state.myId} />
          <DataViz status={this.state.status} myId={this.state.myId} />
          <img src={logo} alt="Logo TeleAgriCulture" class="App-LogoCentral" />
          <Chat status={this.state.status} myId={this.state.myId} />
        </div>
      </div>
    );
  }
}

export default App;
