import React, { Component } from "react";

import "./styles/DataViz.css";

class DataViz extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	shouldComponentUpdate() {
		let f = document.getElementById("DataViz-Frame");
		if (!f) {
			return true;
		}
		return false;
	}

	render() {
		//setTimeout(this.sendColors, 100);
		return (
			<div className="DataViz">
				<iframe
					className="DataViz-Frame"
					id="DataViz-Frame"
					title="DataViz"
					xsrc={
						"//www.teleagriculture.org/splash/tac_splash/?cacheBust=" +
						new Date().getTime()
					}
				/>
			</div>
		);
	}
}

export default DataViz;
