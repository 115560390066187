import React, { Component } from "react";

import KitLogo from "./KitLogo";

import "./styles/Ranking.css";

class Ranking extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	renderRankViz(kitId) {
		const devs = this.props.status.deviations[kitId];
		const colorArr = this.props.status.colors[kitId];
		const color = `hsl(${colorArr.h}, ${colorArr.s}%, ${colorArr.l}%)`;
		delete devs.sum;
		const values = Object.values(devs);
		// const min = Math.min(...values);
		// const max = Math.max(...values);
		const min = -0.25;
		const max = 0.25;
		const r = 40 / (max - min);
		const o = 20;
		const count = values.length;
		let path = "M";
		for (let i = 0; i <= count; ++i) {
			const d = (values[i % count] - min) * r + o;
			path +=
				Math.round(
					Math.cos(-(Math.PI * 2 * i) / count - Math.PI / 2) * d + 50
				) +
				"," +
				Math.round(
					Math.sin(-(Math.PI * 2 * i) / count - Math.PI / 2) * d + 50
				) +
				" L";
		}
		path = path.replace(/L$/, "Z");
		return (
			<svg
				width="100"
				height="100"
				viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid meet"
			>
				<circle cx="50" cy="50" r={40} />
				<path d={path} fill={color} />
			</svg>
		);
	}

	render() {
		let rankingCards;
		const kits = this.props.status.kits;
		if (this.props.status && this.props.status.ranking) {
			rankingCards = this.props.status.ranking.map((kitId, idx) => {
				const colorArr = this.props.status.colors[kitId];
				const color = `hsl(${colorArr.h}, ${colorArr.s * 0.75}%, ${
					colorArr.l
				}%)`;
				const kit = kits[kitId];
				const data = this.props.status.sensor_data[kitId];
				const dev = this.props.status.deviations[kitId];
				const chemData = this.props.status.chem_data[kitId];
				if (chemData) {
					delete chemData["timestamp"];
				}
				return (
					<div
						key={kitId}
						className="Ranking-Card"
						style={{ borderColor: color }}
					>
						<header>
							<span className="Ranking-Rank" style={{ backgroundColor: color }}>
								{idx + 1}
							</span>
							<h3>
								{kits[kitId].name}
								<span className="Kit-Location">{kits[kitId].location}</span>
							</h3>
							<KitLogo kit={kit} />
						</header>
						{this.renderRankViz(kitId)}
						<table class="Ranking-SensorData">
							<tbody>
								{Object.keys(data).map(key => (
									<tr key={key}>
										<th>{key}</th>
										<td>{data[key].toFixed(2)}</td>
										<td>{Math.round(dev[key] * 100)}%</td>
									</tr>
								))}
							</tbody>
						</table>
						{chemData && (
							<table class="Ranking-ChemData">
								<tbody>
									{Object.keys(chemData).map(key => (
										<tr key={key}>
											<td>{Math.round(dev[key] * 100)}%</td>
											<td>
												{chemData[key] && chemData[key].toFixed
													? chemData[key].toFixed(2)
													: chemData[key]}
											</td>
											<th>{key}</th>
										</tr>
									))}
									<tr class="Ranking-SensorTimestamp">
										<td colspan="3">{kit.sensor_timestamp}</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				);
			});
		} else {
			rankingCards = "";
		}
		return <div className="Ranking">{rankingCards}</div>;
	}
}

export default Ranking;
