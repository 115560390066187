import React, { Component } from "react";

import KitLogo from "./KitLogo";

import "./styles/Chat.css";

import testwave from "./assets/test.wav";
// import testwave2 from "./assets/test.wav";

class Chat extends Component {
	constructor(props) {
		super(props);
		this.state = { currentIdx: -1 };
	}

	showCurrentMessage = () => {
		console.log(
			"showCurrentMessage",
			this.state.currentIdx,
			this.props.status.chat.length
		);
		if (this.state.currentIdx < this.props.status.chat.length) {
			const curMsg = this.props.status.chat[this.state.currentIdx];
			this.setState({ currentIdx: this.state.currentIdx + 1 });
			// timer to be replaced by playing audio
			this.timer = setTimeout(this.showCurrentMessage, 10000);
			fetch("http://localhost:4444/generate", {
				method: "POST",
				body: JSON.stringify({
					"kit-id": curMsg.from,
					text: curMsg.text,
					"text-id": curMsg.text_id
				})
			})
				.then(resp => resp.json())
				.then(result => {
					console.log("generated", result);
					if (result.url) {
						document.getElementById("Chat-Audio").src = result.url;
						document.getElementById("Chat-Audio").play();
					}
				})
				.catch(err => {
					console.warn("generating error", err);
				});
		}
	};
	componentDidMount() {
		this.timer = setTimeout(this.showCurrentMessage, 500);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	componentDidUpdate(prevProps, prevState) {
		const logEnd = document.getElementById("Chat-Log-End");

		if (logEnd) {
			logEnd.scrollIntoView({ behavior: "smooth", block: "end" });
		}
		if (prevProps.status !== this.props.status) {
			console.log("Restarting chat log");
			this.setState({ currentIdx: 0 });
			clearTimeout(this.timer);
			this.timer = setTimeout(this.showCurrentMessage, 500);
		}
	}

	renderMessage(msg, idx) {
		const colorArr = this.props.status.colors[msg.from];
		const color = `hsla(${colorArr.h}, ${colorArr.s}%, ${colorArr.l}%, 0.1)`;
		const borderColor = `hsla(${colorArr.h}, ${colorArr.s}%, ${
			colorArr.l
		}%, 1)`;
		let classNames = ["Chat-Message"];
		if (msg.from === this.props.myId) {
			classNames.push("-FromMe");
		}
		if (msg.to === this.props.myId) {
			classNames.push("-ToMe");
		}

		let metricFrom;
		let metricTo;
		if (this.props.status.sensor_data[msg.from][msg.metric]) {
			metricFrom = this.props.status.sensor_data[msg.from][msg.metric];
			metricTo = this.props.status.sensor_data[msg.to][msg.metric];
		} else {
			metricFrom = this.props.status.chem_data[msg.from][msg.metric];
			metricTo = this.props.status.chem_data[msg.to][msg.metric];
		}
		let sign = metricFrom < metricTo ? "<" : ">";
		sign = sign.padStart(
			Math.abs(
				this.props.status.relative_deviations[msg.from][msg.to][msg.metric]
			) / 0.5,
			sign
		);
		const kits = this.props.status.kits;
		return (
			<div
				className={classNames.join(" ")}
				key={idx}
				style={{
					backgroundColor: color,
					borderColor: borderColor
				}}
			>
				<header>
					<span className="Chat-Message-From">
						{kits[msg.from].name}
						<KitLogo kit={kits[msg.from]} />
					</span>
					<span className="Chat-Message-Metric">
						{" "}
						{msg.metric} {metricFrom} {sign} {metricTo}
					</span>
				</header>
				<p>
					<span className="Chat-Message-To">@{kits[msg.to].name}:</span>
					<span
						className="Chat-Message-Prefix"
						dangerouslySetInnerHTML={{ __html: msg.prefix }}
					/>{" "}
					<span className="Chat-Message-Text">{msg.text}</span>{" "}
					<span
						className="Chat-Message-Emoji"
						dangerouslySetInnerHTML={{ __html: msg.emoji }}
					/>
				</p>
			</div>
		);
	}

	render() {
		return (
			<div className="Chat">
				<div className="Chat-Log">
					{this.props.status &&
					this.props.status.chat &&
					this.props.status.chat.length
						? this.props.status.chat.map((msg, idx) => {
								if (idx < this.state.currentIdx) {
									return this.renderMessage(msg, idx);
								} else {
									return "";
								}
						  })
						: ""}
					<div id="Chat-Log-End">
						<audio id="Chat-Audio" xautoplay xcontrols xsrc={testwave} />
					</div>
				</div>
			</div>
		);
	}
}

export default Chat;
